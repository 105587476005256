<template>
  <div>
    <b-form id="alter-form" class="alter-form" @submit="onSubmit">
      <b-card class="custom-card flex-grow-1" border-variant="light">
        <b-form-row>
          <b-col>
            Tipo
          </b-col>
          <b-col>
            <b-form-select
              v-model.lazy.trim="form.fileType"
              :state="validateField('fileType')"
              :options="fileTypeOptions"
              v-on:change="onChangeFileType(form.fileType)"
            />
          </b-col>
        </b-form-row>
        <b-form-row v-if="form.fileType === DocumentType.CALENDAR_INVOICES">
          <b-col>
            REFERÊNCIA
          </b-col>
          <b-col>
            <b-form-input v-model.lazy.trim="form.referency" />
          </b-col>
        </b-form-row>
        <b-form-row
          v-if="
            ![
              DocumentType.ECONOMIC_PANEL,
              DocumentType.CALENDAR_INVOICES,
              DocumentType.ORGANIZATIONAL_CHART,
            ].includes(form.fileType)
          "
        >
          <b-col>
            CRM
          </b-col>
          <b-col>
            <b-form-input
              v-model.lazy.trim="form.crm"
              type="number"
              :state="validateField('crm')"
            />
          </b-col>
        </b-form-row>
        <b-form-row v-if="form.fileType !== DocumentType.CALENDAR_INVOICES">
          <b-col v-if="form.fileType == DocumentType.ORGANIZATIONAL_CHART">
            ATUALIZADO EM
          </b-col>
          <b-col v-else>
            Competência
          </b-col>
          <b-col v-if="form.fileType == DocumentType.INCOME_REPORT">
            <custom-mask-input
              :initialValue="form.competency"
              :options="yearOnlyMask"
              :state="validateFieldLength('competency', 4)"
            />
          </b-col>
          <b-col v-else>
            <custom-mask-input
              :initialValue="form.competency"
              :options="monthYearDateMask"
              :state="validateFieldLength('competency', 7)"
            />
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <UploadFile
              ref="upload-file-component"
              :accept="
                form.fileType !== DocumentType.SUPPORT_FUND
                  ? 'application/pdf'
                  : 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              "
              @updateFile="eventToUpdateFile"
              description="Anexar documento"
              msgErro="É necessário inserir um anexo com a comprovação."
              :state="validateFieldFile('files')"
              :required="true"
            />
          </b-col>
        </b-form-row>
      </b-card>
      <b-button
        form="alter-form"
        type="submit"
        class="mt-4"
        variant="primary"
        id="submitButtonText"
      >
        Enviar
      </b-button>
    </b-form>
  </div>
</template>

<script>
import UploadFile from "../../../../components/upload-file/UploadFile.vue";
import {
  validateField,
  requiredFieldsFilled,
  validateFieldFile,
} from "../../../../utils/validate/validate.js";
import { DocumentType } from "../../../../static-data/DocumentType";
import CustomMaskInput from "../../../../components/forms/custom-mask-input/CustomMaskInput.vue";
import moment from "moment";

const InvalidEventHandler = () => {
  console.warn("Invalid onChange handler");
};

const MonthYearDateMask = {
  date: true,
  delimter: "/",
  datePattern: ["m", "Y"],
  onValueChanged: InvalidEventHandler,
};

const YearOnlyMask = {
  numericOnly: true,
  blocks: [4],
  onValueChanged: InvalidEventHandler,
};

const DefaultForm = {
  fileType: null,
  crm: null,
  competency: null,
  files: null,
};

export default {
  props: ["onSubmitHandler"],
  components: { UploadFile, CustomMaskInput },
  mounted() {
    this.initFileTypeOptions();
  },
  computed: {
    DocumentType() {
      return DocumentType;
    },
  },
  data() {
    return {
      fileTypeOptions: [],
      formSubmited: false,
      form: {
        ...DefaultForm,
        fileType: DocumentType.INCOME_REPORT,
      },
      yearOnlyMask: {
        ...YearOnlyMask,
        onValueChanged: this.onChangeCompetency,
      },
      monthYearDateMask: {
        ...MonthYearDateMask,
        onValueChanged: this.onChangeCompetency,
      },
      validations: {},
      validateField,
      requiredFieldsFilled,
      validateFieldFile,
    };
  },
  methods: {
    validateFieldLength(name, requiredLength) {
      let value = this.form[name];
      let validation = true;
      validation = !!value;
      validation = validation && value.length == requiredLength;

      this.validations[name] = validation;
      if (!this.formSubmited) return null;

      return validation;
    },
    initFileTypeOptions() {
      this.fileTypeOptions = [
        {
          text: "Informe de Rendimentos",
          value: DocumentType.INCOME_REPORT, //year
        },
        {
          text: "Extrato de Cotas",
          value: DocumentType.EXTRACT,
        },
        {
          text: "Painel Econômico",
          value: DocumentType.ECONOMIC_PANEL,
        },
        {
          text: "Organograma",
          value: DocumentType.ORGANIZATIONAL_CHART,
        },
      ];
    },
    onChangeFileType(fileType) {
      this.$refs["upload-file-component"].removeAllFiles();
      if ([DocumentType.ECONOMIC_PANEL, DocumentType.CALENDAR_INVOICES, DocumentType.ORGANIZATIONAL_CHART].includes(fileType))
        this.onNotvalidateField("crm", fileType);

      if (fileType === DocumentType.CALENDAR_INVOICES)  
        this.onNotvalidateField("competency", fileType);
    },
    onNotvalidateField(name) {
      this.validations[name] = true;
    },
    onChangeCompetency(e) {
      this.form.competency = e.target.value;
    },
    eventToUpdateFile(files) {
      this.form.files = files;
    },
    onSubmit(e) {
      if (this.form.fileType === DocumentType.CALENDAR_INVOICES) {
        this.form.competency = moment(Date.now()).format("MM/YYYY");
        this.form.crm = "-999";
      }
      e.preventDefault();
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        this.$props.onSubmitHandler(this.form);
        this.formSubmited = false;
        this.$refs["upload-file-component"].removeAllFiles();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div.custom-card {
  padding: 0 !important;
}

div.form-row {
  padding: 0.5rem;
  border-bottom: 1px solid $gray-300;
  &:last-child {
    border-bottom: none;
  }
}

input.form-control {
  text-align: right;
  border: 1px solid transparent;
}

select.custom-select {
  text-align: right;
  border: 1px solid transparent;
}

.label-right {
  width: 100%;
  text-align: right;
}
</style>