<template>
  <b-container class="d-flex" fluid>
    <PdfManagementForm class="custom-card-size" :onSubmitHandler="onSubmit" />
  </b-container>
</template>

<script>
import PdfManagementForm from "../forms/PdfManagementForm.vue";
import { documentPDFUpload } from "../../../../services/document-upload/document-file-service";
import { inject } from "@vue/composition-api";
export default {
  name: "file-management-content",
  components: {
    PdfManagementForm,
  },
  setup() {
    const globalLoading = inject("globalLoading");
    return { globalLoading };
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async onSubmit(formData) {
      try {
        this.globalLoading = true;
        await documentPDFUpload(formData);
        this.globalLoading = false;
      } catch (error) {
        this.globalLoading = false;
        console.error("Error OnSubmit", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-card-size {
  width: 100%;
}
</style>
